import { FC } from "react";
interface Props {
  id?: string;
}
const Player: FC<Props> = ({ id }) => {
	return (
		<div className="w-full h-full overflow-hidden">
			<object
				data={`https://www.youtube.com/embed/${id}?modestbranding=1&amp;rel=0&amp;showinfo=0`}
				className="w-full h-full"
			>This video doesn't exist</object>
		</div>
	);
};

export default Player;
