import { AppStateProvider } from '@hooks/useAppReducer';
import { LoadingProvider } from '@hooks/useLoading';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { App } from './App';
import { reducer } from './reducers';

const queryClient = new QueryClient();

const composeProviders = (providers: any) => {
	return providers.reduce((Acc: any, Provider: any) => {
		return (props: any) => <Provider><Acc {...props} /></Provider>;
	});
}
const MultiProvider = composeProviders(
	[
		(props: any) => <QueryClientProvider client={queryClient} children={props.children}/>,
		(props: any) => <AppStateProvider children={props.children} store={reducer}/>,
		(props: any) => <LoadingProvider children={props.children} />
	]
);

const Root = () => {
	return (
		<BrowserRouter>
			<MultiProvider>
				<ToastContainer />
				<App />
			</MultiProvider>
		</BrowserRouter >
	);
};

export { Root };
