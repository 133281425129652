import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithRedirect, signOut, User } from 'firebase/auth'
import { useEffect, useState } from "react"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyD4osasSXfPpDhsEbrRb16LXZbLopCmyZA",
	authDomain: "i1ove-c70b3.firebaseapp.com",
	projectId: "i1ove-c70b3",
	storageBucket: "i1ove-c70b3.appspot.com",
	messagingSenderId: "634798753456",
	appId: "1:634798753456:web:9bb75e8b300257bf439d75",
	measurementId: "G-RDXZJF7GCK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

// google provider 
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export const useFirebase = () => {
	const [user, setUser] = useState<User | null>();

	// for google
	const signInWithGoogle = async () => {
		const { user } = await signInWithRedirect(auth, googleProvider);
		setUser(user);
	}

	//for facebook
	const signInWithFacebook = async () => {
		const { user } = await signInWithRedirect(auth, facebookProvider);
		setUser(user);
	}

	//sign out
	const handleSignOut = async (callback?: () => void) => {
		await signOut(auth);
		setUser(null);
		callback && callback();
	}


	// for user state change or observe
	useEffect(() => {
		onAuthStateChanged(auth, user => {
			setUser(user);
		})
	}, [])


	// return [user, setUser];
	return {
		user,
		handleSignOut,
		signInWithGoogle,
		signInWithFacebook,
	}

}