import CryptoJS from 'crypto-js';

import {randomInRange} from "./utils";

/**
 * Encrypt data from input string with scecret key
 * @param {*} value 
 * @param {*} secretKey 
 */
export const encryptData = (value:any, secretKey:string='12345') => {
	return CryptoJS.AES.encrypt(value, secretKey);
}

/**
 * Decrypt data from encrypted string
 * @param {*} encrypted 
 * @param {*} secretKey 
 */
export const decryptData = (encrypted:any, secretKey:string='12345') => {
	let decryptBytes = CryptoJS.AES.decrypt(encrypted, secretKey);
	return decryptBytes.toString(CryptoJS.enc.Utf8);
}

export const base64 = (value:string|undefined) => {
	var wordArray = CryptoJS.enc.Utf8.parse(value || '');
	let result = CryptoJS.enc.Base64.stringify(wordArray);
	let firstChar = randomInRange(97,122); //random 'a..z' letter
	let secondChar = randomInRange(65,90); //random 'A..Z' letter
	result += String.fromCharCode(firstChar);
	result += String.fromCharCode(secondChar);
	return result;
}

export const decodeBase64 = (encoded:string|undefined) => {
	encoded = encoded?.substring(0, encoded?.length-2);
	var parsedWordArray = CryptoJS.enc.Base64.parse(encoded||'');
	return parsedWordArray.toString(CryptoJS.enc.Utf8);
}

