import { FaCalendar, FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Hero = ()=>{
	return (
		<section className=" min-h-[unset] lg:bg-[right_center] bg-cover bg-center min-h-[750px] h-full max-h-[1000px] flex justify-start items-center" 
			style={{backgroundImage:`url(./images/home/hero-bg1.jpg)`}}>
			<div className=" max-w-[540px] md:max-w-[720px] lg:max-w-[960px] lg:w-[950px] xl:max-w-[1320px] xl:w-[1320px]">
				<div className="mt-5 md:mt-24 lg:mt-24 lg:max-w-[600px] mx-20">
					<p className="mb-2.5 box-border text-4xl font-bold text-[rgba(226,215,3,1)]">i<span className="text-[45px]">1</span>ove Channel</p>
					<h1 className="mb-7 box-border text-6xl text-white leading-[1.2]">
                        Unlimited <strong className="box-border text-[rgba(226,215,3,1)]">Movie</strong>, Audio Book, &amp; More.
					</h1>
					<div className="mb-12 box-border flex flex-wrap items-center justify-start text-xl ">
                        Happy users change the world. Let us make you happy.
					</div>
					<Link to="/movies" className="w-[200px] box-border flex cursor-pointer items-center gap-2.5 text-xs font-bold uppercase text-white tracking-[2px] rounded-[50px]
                    uppercase py-[16px] px-[30px] border-2 border-yellow-200 hover:bg-yellow-600">
						<FaPlay className="box-border block text-lg " aria-label="play" role="img" name="play"></FaPlay>
						<span className="box-border block ">Watch now</span>
					</Link>
				</div>
			</div>
		</section>
	);
}