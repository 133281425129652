import { AccessTrade } from "@components/Ads/AccessTrade";
import { Amazon } from "@components/Ads/Amazon";
import { useGeoInfo } from "@hooks/useGeoInfo";
import { FC, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { LOCAL_STORAGE_HISTORY_KEY, MAX_VIEW_FREE } from "@/consts";
import { useFirebase } from "@/hooks/useFirebase";
import { VideoDetail } from "@/types";
import { getLocalData, storeLocalData } from "@/utils/storage";

import Comment from "./Comment";
import MetaData from "./Metadata";
import Player from "./Player";
import Similar from "./Similar";
interface WatchViewProps {
	data?:VideoDetail;
};

const WatchView: FC<WatchViewProps> = ({data}) => {
	const {user} = useFirebase();
	const navigate = useNavigate();
	//const {countryName, countryCode} = useGeoInfo();
	let countryCode = "JP";
	useEffect(() => {
		if (!data) return;
		let existing:string[] = JSON.parse(getLocalData(LOCAL_STORAGE_HISTORY_KEY)||'[]');
		if (!existing) return;
		// Check if user not login and maximum video is reached, force sign in
		if(!user && existing.length >= MAX_VIEW_FREE){
			navigate("/sign-in");
			return;
		}
		existing = existing.filter((item:string) => item !== data.id);
		existing.push(data.id);
		storeLocalData(LOCAL_STORAGE_HISTORY_KEY, existing);
	}, [data, navigate, user]);

	return (
		<>
			<div className="flex justify-center">
				<div className="mx-[4vw] lg:mx-[6vw] flex-1">
					<div className="flex flex-col md:flex-row gap-20 my-7">
						<div className="flex flex-col items-stretch flex-grow">
							<div className="w-full h-0 pb-[56.25%] relative">
								{countryCode=="VN"?<AccessTrade brand="tiki" video={data}/>:null}
								{countryCode==="JP"?<Amazon video={data} />:null}
								<div className="absolute inset-0 w-full h-full bg-black">
									<Player
										id={data?.id||''}
									></Player>
								</div>
							</div>

							<MetaData data={data} />

							<Comment data={data} />
						</div>
						<Similar data={data} />
					</div>
				</div>
			</div>
		</>
	);
};

export default WatchView;
