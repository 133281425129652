import { FC, FormEvent, useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";

import { searchVideos } from "@/api/VideoAPI";
import { VideoDetail } from "@/types";

import TopSearches from "./TopSearches";

interface SearchBoxProps {
	category?: string;
	autoFocus?: boolean;
}

const SearchBox: FC<SearchBoxProps> = ({ autoFocus, category }) => {
	const [inputValue, setInputValue] = useState("");
	const [videos, setVideos] = useState<VideoDetail[]>([]);

	const timeoutRef = useRef<any>(null);

	const queryVideos = async(keyword:string)=>{
		const {videos} = await searchVideos({ keyword, maxPerPage: 5, category});
		setVideos(videos);
	}

	useEffect(() => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);

		setVideos([]);

		if (!inputValue.trim()) return;

		timeoutRef.current = setTimeout(async () => {
			queryVideos(inputValue.trim());
		}, 500);
	}, [inputValue]);

	const handleFormSubmit = (e: FormEvent) => {
		e.preventDefault();

		if (inputValue.trim()) {
			queryVideos(inputValue.trim());
		}
	};

	return (
		<>
			<div className="relative group w-full">
				<form onSubmit={handleFormSubmit} className="relative">
					<input
						value={inputValue}
						onKeyDown={(e) => e.stopPropagation()}
						onKeyUp={(e) => e.stopPropagation()}
						onKeyPress={(e) => e.stopPropagation()}
						onChange={(e) => setInputValue(e.target.value)}
						className="bg-transparent outline-none border border-gray-600 w-full rounded-full py-2 pl-4 pr-8"
						type="text"
						placeholder="Search..."
						autoFocus={autoFocus}
					/>
					<button className="absolute right-2 top-1/2 -translate-y-1/2">
						<FaSearch className="w-5 h-5" />
					</button>
				</form>
			</div>
			<h1 className="text-xl my-6">Top Searches</h1>
			<TopSearches videos={videos} />
		</>
	);
};

export default SearchBox;
