import { Meta } from "@components/Shared/Meta";
import { FC, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { getVideoDetail } from "@/api/VideoAPI";
import WatchView from "@/components/WatchView";
import SideBarLayout from "@/layouts/SideBarLayout";
import { VideoDetail } from "@/types";
interface Props {
}
export const VideoDetailPage: FC<Props> = () => {
	const { id, category } = useParams();
	const [video, setVideo] = useState<VideoDetail>();
	//console.log("ID", id, category);
	const getVideoData = async (id: string, category:string) => {
		const data = await getVideoDetail(id, category);
		setVideo(data);
	}
	useEffect(() => {
		if (id && typeof (id) == "string" && typeof(category)=="string")
			getVideoData(id, category);
	}, [id, category]);

	return (
		<>
			<Meta title={video?.title} desc={video?.desc} img={video?.thumbs[1]}/>
			<SideBarLayout>
				<WatchView data={video} />
			</SideBarLayout>
		</>
	);
};
