import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom';

import { useFirebase } from "@/hooks/useFirebase";

import { MobileMenuItem } from "./MenuItem";
export const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { user, handleSignOut } = useFirebase();
	const navigate = useNavigate();
	return (
		<nav className="bg-gray-800">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between h-16">
					<div className="flex items-center cursor-pointer">
						<div className="flex-shrink-0">
							<Link to="/">
								<img className="h-8 w-16" src="/images/logo3.png" alt="i1ove" />
							</Link>
						</div>
					</div>
					<div className="-mr-2 flex md:hidden">
						<button
							onClick={() => setIsOpen(!isOpen)}
							type="button"
							className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
							aria-controls="mobile-menu"
							aria-expanded="false"
						>
							<span className="sr-only">Open main menu</span>
							{!isOpen ? (
								<img src="/images/open_icon.svg" alt="" className="w-8 h-8" />
							) : (
								<img src="/images/close_icon.svg" alt="" className="w-8 h-8" />
							)}
						</button>
					</div>
				</div>
			</div>

			{isOpen && (
				<div className="md:hidden" id="mobile-menu">
					<div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
						<MobileMenuItem title="Home" link="/"/>
						<MobileMenuItem title="Movies" link="/movies"/>
						<MobileMenuItem title="Audio Books" link="/audiobooks"/>
						<MobileMenuItem title="Study Japanese" link="/japanese"/>
						<MobileMenuItem title="Study Investment" link="/investment"/>
						<MobileMenuItem title="History" link="/history"/>
						{!user && (
							<MobileMenuItem title="Sign In" link="/sign-in"/>
						)}
						{user && (
							<div
								onClick={() => { handleSignOut(); navigate("/"); }}
								className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
							>
								Sign Out
							</div>
						)}
					</div>
				</div>
			)}
		</nav>
	);
};
