import { Link,useLocation } from "react-router-dom";

export const ErrorPage = () => {
	const location = useLocation();

	return (
		<div className="flex flex-col min-h-screen justify-center items-center gap-4">
			<img className="w-full max-w-[200px] h-auto" src="/images/error.png" alt="" />
			<p className="text-xl">Something went wrong</p>

			{location.pathname !== "/" && (
				<Link to="/">
					<div className="text-primary">Return home</div>
				</Link>
			)}
		</div>
	);
};
