import { useEffect,useState } from 'react';
import { isAndroid, isBrowser, isIOS,isMobile } from 'react-device-detect';

const isWebview = () => {
	if (typeof window === undefined) { return false };

	let navigator: any = window.navigator;

	const standalone = navigator.standalone;
	const userAgent = navigator.userAgent.toLowerCase();
	const safari = /safari/.test(userAgent);
	const ios = /iphone|ipod|ipad/.test(userAgent);

	return ios ? !standalone && !safari : userAgent.includes('wv');
}
const isFacebookApp = ()=>{
	let result = false;
	if(typeof navigator !== "undefined" && typeof window !== "undefined") {
		var ua = navigator.userAgent || navigator.vendor;
		if ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)) {
			result = true;
		}
	}
	return result;
}
export const useUserAgent = () => {
	return {
		isMobile,
		isWebview:isWebview(),
		isAndroid,
		isBrowser,
		isIOS,
		isFacebookApp: isFacebookApp()
	}
};