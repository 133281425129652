import { useLoading } from "@hooks/useLoading";
import { useNotify } from "@hooks/useNotify";
import { useUserAgent } from "@hooks/useUserAgent";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Navigate from "@/components/Shared/Navigate";
import { LOCAL_STORAGE_LAST_URL } from "@/consts";
import { useFirebase } from "@/hooks/useFirebase";
import { getLocalData } from "@/utils/storage";

export const SignInPage: FC = () => {
	const {user, signInWithGoogle, signInWithFacebook} = useFirebase();
	const {isFacebookApp} = useUserAgent();
	const {error_description} = useParams();
	const notify = useNotify();
	const navigate = useNavigate();
	const {isLoading, setLoading} = useLoading();

	const redirectAfterSignIn = async()=>{
		let url = getLocalData(LOCAL_STORAGE_LAST_URL);
		navigate(url?url.replaceAll('"',''):"/");
	}
	const handleSignIn = async (provider: "google" | "facebook") => {
		setLoading(true);
		try{
			if(provider==="google"){
				await signInWithGoogle();
			}else{
				await signInWithFacebook();
			}
			notify("INFO", "Login success");
		}catch(err){
			notify("ERROR", "You cannot login. Please try again");
		}finally{
			setLoading(false);
		}
	};

	useEffect(()=>{
		if(user){
			notify("INFO", "Login success");
			redirectAfterSignIn();
		}
	},[user]);

	return (
		<div className={`min-h-screen w-screen bg-no-repeat bg-cover bg-center`} style={{backgroundImage: "url('./images/bg3.jpg')"}}>
			<div className="w-full min-h-screen flex justify-center items-center bg-[#00000056]">
				<div className="w-[90vw] max-w-[350px] bg-black p-10 flex flex-col items-center gap-6 rounded-xl">
					<h1 className="text-3xl font-semibold">Sign In</h1>
					<span className="text-center whitespace-pre-wrap">Please login with your mail to view unlimited.(FREE) </span>
					{error_description && (
						<div className="p-3 bg-red-200 text-red-600 border border-red-400 w-full rounded">
								Error: {error_description}
						</div>
					)}
					{!isFacebookApp && (
						<button
							disabled={isLoading}
							onClick={() => handleSignIn("google")}
							className="flex items-center bg-white text-black p-3 gap-3 rounded-md cursor-pointer hover:brightness-90 disabled:!brightness-75 disabled:!cursor-default transition duration-300 w-full"
						>
							<img className="w-6 h-6" src="/images/google.svg" alt="" />

							<span>Sign In With Google</span>
						</button>
					)}

					<button
						disabled={isLoading}
						onClick={() => handleSignIn("facebook")}
						className="flex items-center bg-primary text-white p-3 gap-3 rounded-md cursor-pointer hover:brightness-90 disabled:!brightness-75 disabled:!cursor-default transition duration-300 w-full"
					>
						<img className="w-6 h-6" src="/images/facebook.svg" alt="" />

						<span>Sign In With Facebook</span>
					</button>
				</div>
			</div>
		</div>
	);
};
