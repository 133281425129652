export const LOCAL_STORAGE_HISTORY_KEY = 'review_histories';
export const LOCAL_STORAGE_LAST_URL = 'review_last_url';
export const MAX_ITEM_PER_PAGE = 50;
export const MAX_VIEW_FREE = 5;
export const IMAGE_CARD_SIZE = {
	0: {
		width: 200,
		height: 100,
	},
	1: {
		width: 175,
		height: 246,
	},
};

export const resizeImage = (
	url: string,
	width: number | string = "",
	height: number | string = ""
) =>
	`https://images.weserv.nl/?url=${encodeURIComponent(
		url
	)}&w=${width}&h=${height}&fit=cover&output=webp`;