import { CountDown } from "@components/Ads/CountDown";
import { FC, useEffect, useRef, useState } from "react";
import { TfiControlSkipForward } from "react-icons/tfi";
import ImageFadeIn from "react-image-fade-in";

import { VideoDetail } from "@/types";
import { openInNewTab, randomInRange } from "@/utils/utils";

import data from "./data.json";
interface Props {
	video?: VideoDetail;
	brand?: "tiki" | "shopee" | "lazada";
	skipTime?: number; //time in seconds
}
export const Amazon: FC<Props> = ({ video, brand = "tiki", skipTime = 5 }) => {
	const [isOpen, setOpen] = useState(true);
	//const [data, setData] = useState(data);
	const getAdsData = async () => {
		let url = `json/amazon.json`
		let response: any = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		});
		let json = await response.json();
		//console.log("Data:", json, url);
		//setData(response.data);
	}

	useEffect(() => {
		setOpen(true);
		//getAdsData();
	}, [video?.id]);

	if (!isOpen) return <></>;

	return (
		<div className="absolute w-full h-full overflow-hidden z-10 cursor-pointer">
			<div className="w-full h-full grid grid-cols-lg bg-white">
				{Array.from(Array(20).keys()).map((value, index) => {
					let rnd = randomInRange(0, data.length);
					return (<iframe title="Amazon" sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
						className="w-[240px] h-[240px]"
						marginWidth={0}
						marginHeight={0}
						scrolling="no"
						frameBorder={0}
						src={data[rnd]}
					>Ads</iframe>);
				})}
			</div>
			<CountDown onSkipClick={() => setOpen(false)} skipTime={skipTime} />
		</div>
	);
};
