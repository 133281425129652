import { Navigate, useParams } from 'react-router-dom';

import { LOCAL_STORAGE_LAST_URL } from '@/consts';
import { storeLocalData } from '@/utils/storage';

export const PrivateRoute = ({user, children}:any)=>{
	const { category } = useParams();
	console.log("Private Route", category);
	if(category){
		storeLocalData(LOCAL_STORAGE_LAST_URL, window.location.href.split(window.location.host)[1]);
	}
	if (!user && (!category || category !== "movie")) {
		console.log(!user, !category, category !== "movie");
		return <Navigate to="/sign-in" replace />;
	}
    
	return children;
}