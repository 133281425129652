import classNames from "classnames"
import { Link, useLocation } from "react-router-dom"

interface Props{
    children?: React.ReactNode;
    link:string;
    title:string;
}
export const MenuItem = ({children, link, title}:Props)=>{
	const location = useLocation();
	return (
		<Link to={link}>
			<div
				className={classNames("flex items-center gap-2 transition", {
					"text-primary border-r-4 border-primary hover:brightness-125":
                        location.pathname === link,
					"text-gray-400 hover:text-gray-300":
                        location.pathname !== link,
				})}
			>
				{children}
				<p className="block sm:hidden xl:block">{title}</p>
			</div>
		</Link>
	)
}

export const MobileMenuItem = ({link, title}:Props)=>{
	return (
		<Link to={link}>
			<div className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
				{title}
			</div>
		</Link>
	)
}