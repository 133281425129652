import { FC, useEffect, useRef, useState } from "react";
import { TfiControlSkipForward } from "react-icons/tfi";

import { openInNewTab } from "@/utils/utils";


interface Props {
    skipTime?: number; //time in seconds
    onSkipClick?:()=>void;
}
export const CountDown: FC<Props> = ({ skipTime = 5, onSkipClick }) => {
	const [countDown, setCountDown] = useState(skipTime);
	const countTime = useRef<ReturnType<typeof setInterval> | null>(null);

	useEffect(() => {
		if (countTime.current) clearInterval(countTime.current);
		if (countDown > 0) {
			countTime.current = setInterval(() => {
				setCountDown(countDown - 1);
				if (countDown <= 0 && countTime.current) {
					clearInterval(countTime.current);
				}
			}, 1000);
		}
	}, [countDown]);

	return (
		<div className="absolute right-0 flex bottom-5 p-2 items-center text-center cursor-pointer w-[100px] h-[40px] bg-black text-white"
			onClick={() => countDown <= 0 && onSkipClick && onSkipClick()}
		>
			{countDown > 0 ? `Skip in ${countDown}s` : (
				<>
					<span className="pl-5 pr-2">Skip</span><TfiControlSkipForward />
				</>
			)}
		</div>
	);
};