import React from "react";
import ReactDOM from "react-dom";

export const Details=({ defaultOpen = false, summary, children }:any)=> {
	const [open, setOpen] = React.useState(defaultOpen);
	console.log(open);
	return (
		<div>
			{!open && <div>
				{summary}... 
				<span onClick={()=>setOpen(true)} className="font-bold cursor-pointer text-yellow-500">more</span>
			</div>}
			{open && <div>
				{children}
				<div onClick={()=>setOpen(!open)} className="font-bold cursor-pointer text-yellow-500">Show less</div>
			</div>
			}
		</div>
	);
}