import classNames from "classnames";
import { FC } from "react";
import {BsTranslate} from "react-icons/bs";
import {
	FaFileAudio,
	FaHistory,
	FaHome,
	FaLanguage,
	FaLock,
	FaPeopleArrows,
	FaSearch,
	FaSignInAlt,
	FaSignOutAlt,
	FaVideo,
} from "react-icons/fa";
import {TbLanguageHiragana} from "react-icons/tb";
import {  useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

import ImageFade from "@/components/Shared/ImageFade";
import { useFirebase } from "@/hooks/useFirebase";

import { MenuItem } from "./MenuItem";

interface SidebarProps {
	sidebarActive: boolean;
	setSidebarActive: Function;
}

const Sidebar: FC<SidebarProps> = ({ sidebarActive, setSidebarActive }) => {
	const { user, handleSignOut } = useFirebase();
	const navigate = useNavigate();
	
	return (
		<>
			<div
				className={classNames(
					"flex-shrink-0 sm:sticky left-auto right-full sm:!right-0 sm:!left-0 fixed top-0 flex flex-col items-stretch py-10 pl-5 xl:pl-10 pr-0 w-[90vw] max-w-[288px] sm:max-w-none sm:w-16 xl:w-72 border-r border-gray-800 h-screen overflow-y-auto z-10 bg-dark sm:bg-transparent sm:!translate-x-0 transition-all duration-500",
					{
						"translate-x-full": sidebarActive,
						"translate-x-0": !sidebarActive,
					}
				)}
			>
				<Link to="/">
					<div className="flex gap-2 items-center">
						<div className="w-[200px] h-[100px] overflow-hidden override-hidden xl:block">
							<div className="w-full h-[70px] bg-center bg-contain bg-no-repeat" style={{ backgroundImage: "url('/images/logo3.png')" }} />
						</div>
						<div className="w-[100px] h-[100px] overflow-hidden override-hidden sm:block xl:hidden ml-[-10px] mr-2">
							<div className="w-full h-[70px] bg-center bg-contain bg-no-repeat" style={{ backgroundImage: "url('/images/icon.png')" }} />
						</div>
					</div>
				</Link>

				<div className="mt-0 sm:mt-4 xl:mt-0 block sm:flex flex-col gap-0 sm:gap-4 xl:block xl:gap-0">
					<p className="text-gray-400 uppercase mt-10 mb-4 block sm:hidden xl:block">
						Menu
					</p>

					<div className="flex flex-col items-stretch gap-3">
						<MenuItem link="/home" title="Home">
							<FaHome className="w-6 h-6 fill-current" />
						</MenuItem>
						<MenuItem link="/movies" title="Movies">
							<FaVideo className="w-6 h-6 fill-current" />
						</MenuItem>
						<MenuItem link="/audiobooks" title="Audio Books">
							<FaFileAudio className="w-6 h-6 fill-current" />
						</MenuItem>
						<MenuItem link="/japanese" title="Study Japanese">
							<TbLanguageHiragana className="w-6 h-6 fill-current" />
						</MenuItem>
						{/* <MenuItem link="/english" title="Study English">
							<BsTranslate className="w-6 h-6 fill-current" />
						</MenuItem> */}
						<MenuItem link="/investment" title="Study Investment">
							<FaPeopleArrows className="w-6 h-6 fill-current" />
						</MenuItem>
						<MenuItem link="/history" title="History">
							<FaHistory className="w-6 h-6 fill-current" />
						</MenuItem>
					</div>

					<p className="text-gray-400 uppercase mt-10 mb-4 block sm:hidden xl:block">
						Personal
					</p>

					{!user ? (
						<Link to="/sign-in">
							<div className="flex items-center gap-2 transition text-gray-400 hover:text-gray-300">
								<FaSignInAlt className="w-6 h-6 fill-current" />
								<p className="block sm:hidden xl:block">Sign In</p>
							</div>
						</Link>
					) : (
						<div className="flex flex-col items-stretch gap-3">
							<div className="flex gap-2 items-center">
								<ImageFade
									src={user.photoURL || ''}
									width={24}
									height={24}
									className="rounded-full"
									alt=""
								/>

								<p className="text-gray-400 block sm:hidden xl:block">
									{user.displayName}
								</p>
							</div>
							<button
								onClick={() => { handleSignOut(); navigate("/"); }}
								className="flex items-center cursor-pointer gap-2 transition text-gray-400 hover:text-gray-300"
							>
								<FaSignOutAlt className="w-6 h-6 fill-current" />
								<p className="block sm:hidden xl:block">Sign Out</p>
							</button>
						</div>
					)}
				</div>
			</div>
			<div
				onClick={() => setSidebarActive(false)}
				className={classNames(
					"bg-[#00000080] z-[5] fixed top-0 left-0 w-full h-full transition-all duration-500 sm:!opacity-0",
					{
						"opacity-100 visible": sidebarActive,
						"opacity-0 invisible": !sidebarActive,
					}
				)}
			></div>
		</>
	);
};

export default Sidebar;
