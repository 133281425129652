import moment from "moment";
import { sort } from "semver";

import { MAX_ITEM_PER_PAGE } from '@/consts';
import { VideoDetail } from '@/types';
import { uppercaseFirstLetter, uppercaseWords } from "@/utils/utils";

import audioBookData1 from './mocks/audio_book1.json';
import audioBookData2 from './mocks/audio_book2.json';
import audioBookData3 from './mocks/audio_book3.json';
import investData1 from './mocks/investment1.json';
import investData2 from './mocks/investment2.json';
import japaneseData1 from "./mocks/japanese1.json";
import japaneseData2 from "./mocks/japanese2.json";
import japaneseData3 from "./mocks/japanese3.json"
import movieData1 from './mocks/movie1.json';
import movieData2 from './mocks/movie2.json';

type SortType = "acs"|"desc";
const processData = (category:string='movie', sort:SortType="acs"): VideoDetail[] => {
	const videos:any = [];
	switch(category){
	case "movie": 
		videos.push(...movieData1);
		videos.push(...movieData2);
		break;
	case "audio_book":
		//videos.push(...audioBookData1);
		videos.push(...audioBookData2);
		videos.push(...audioBookData3);
		break;
	case "japanese":
		videos.push(...japaneseData1);
		videos.push(...japaneseData2);
		videos.push(...japaneseData3);
		break;
	case "investment":
		videos.push(...investData1);
		videos.push(...investData2);
		break;
	}
	let result = videos.sort(( a:VideoDetail, b:VideoDetail ) => sort==="acs" && moment(a.date) < moment(b.date) ? 1 : -1);
	result =  result.map((video:VideoDetail)=>{
		if(video.category === category){
			return{
				...video,
				title: uppercaseWords(video.title).replace("Review",""),
				desc: uppercaseFirstLetter(video.desc),
			}
		}
	}).filter((video:any)=>video != undefined);
	////console.log(result);
	return result;
}
export const searchVideos = async (conditions: any={keyword:'', maxPerPage:0, category:'movie'}, page: number = 0, sort:SortType="acs") => {
	let category = conditions.category || 'movie';
	let keyword = conditions.keyword || '';
	let maxPerPage = conditions.maxPerPage || MAX_ITEM_PER_PAGE;
	let exceptIds = conditions.exceptIds || [];
	// //console.log("Keyword", keyword);

	//process data first
	let videos = await processData(category, sort);
	//filter by keyword
	if(keyword){
		videos = videos.filter((video) => {
			return video.title.toLowerCase().includes(keyword.toLowerCase());
		});
	}
	//filter by ids
	if(exceptIds && exceptIds.length > 0){
		videos = videos.filter((video) => {
			return !exceptIds.includes(video.id);
		});
	}
	const totalPage = videos.length;
	//slide page
	let pageStartIndex = 0;
	let pageEndIndex = maxPerPage;
	if (page > 0) {
		pageStartIndex = Math.max(0, (page - 1) * maxPerPage);
		pageEndIndex = Math.min(page * maxPerPage, videos.length);
	}
	videos = videos.slice(pageStartIndex, pageEndIndex);
	return { videos, totalPage };
}

export const getVideoByIds = async (ids: string[], category='movie') => {
	const processedData = processData(category);
	return processedData.filter(obj => obj.id && ids.includes(obj.id));
}

export const getVideoDetail = async (id: string | undefined, category='movie'):Promise<VideoDetail|undefined> => {
	const processedData = processData(category);
	const result = processedData.find(obj => obj.id === id);
	return result;
}

export const getSimilarVideos = async (data:VideoDetail)=>{
	let similarVideos:VideoDetail[] = [];
	let keywords = [data.title.substring(0,30)];
	keywords.push(...data.title.split(" ").filter((item:string)=>item !== "||" && item !== '|' && item !== ':' && item !== '-'));
	let exceptIds:string[] = [data.id];
	for(let keyword of keywords){
		//console.log("Search keyword", keyword);
		const {videos} = await searchVideos({keyword, exceptIds, maxPerPage:10, category: data.category},1,"desc");
		if(videos){
			videos.length >0 && exceptIds.push(...videos.map(video=>video.id));
			videos.length >0 && similarVideos.push(...videos);
		}
	}
	similarVideos = similarVideos.slice(0, 10);
	return similarVideos;
}