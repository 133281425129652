import { TallCard } from "@components/Home/TallCard";
import { FC, useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { searchVideos } from "@/api/VideoAPI";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { VideoDetail } from "@/types";

import ImageFade from "../Shared/ImageFade";

interface Props {
	category?:string;
	filterConditions?: any;
}
export const VideoList: FC<Props> = ({ category, filterConditions }) => {
	const [allVideos, setVideos] = useState<VideoDetail[]>([]);
	const [totalPage, setTotalPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [, setIsFetching] = useInfiniteScroll(() => fetchNextPage());

	const queryVideos = async () => {
		const { videos, totalPage } = await searchVideos({category, ...filterConditions}, 1);
		setVideos(videos);
		setTotalPage(totalPage);
		setIsFetching(false);
		setCurrentPage(1);
	}

	const fetchNextPage = async () => {
		if (currentPage >= totalPage) return;
		const { videos } = await searchVideos({category, ...filterConditions}, currentPage + 1);
		setCurrentPage(currentPage + 1);
		setVideos([...allVideos, ...videos].filter(video=>video!=undefined));
		setIsFetching(false);
	}
	useEffect(() => {
		queryVideos();
	}, [category, filterConditions]);

	if (allVideos.length === 0) {
		return <div className="text-center my-6">No result found</div>
	}
	// return (
	// 	<div className="grid gap-6 grid-cols-2 sm:grid-cols-lg p-10">
	// 		{
	// 			allVideos.map((video: VideoDetail) => (
	// 				<Link
	// 					to={`/${category}/${video.id}`}
	// 					key={video.id}
	// 				>
	// 					<div className="relative h-0 pb-[163%] bg-dark-lighten rounded overflow-hidden group">
	// 						<div className="absolute top-0 left-0 w-full h-full flex flex-col items-stretch">
	// 							<div className="relative w-full h-0 pb-[140%] flex-shrink-0 group-hover:brightness-[80%] transition duration-300">
	// 								<ImageFade
	// 									width={640}
	// 									className="absolute top-0 left-0 w-full h-full object-cover"
	// 									src={video.thumbs[1]}
	// 									alt=""
	// 								/>
	// 							</div>

	// 							<div className="flex-grow flex items-center">
	// 								<h1 className="w-full whitespace-nowrap overflow-hidden text-ellipsis px-2 group-hover:text-primary transition duration-300 font-bold">
	// 									{video.title.replace("REVIEW PHIM", "")}
	// 								</h1>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</Link>
	// 			))
	// 		}
	// 	</div>
	// );
	return (
		<div className="container my-12 mx-auto px-4 md:px-12 min-h-[100vh]">
			<div className="flex flex-wrap -mx-1 lg:-mx-4">
				{
					allVideos.map((video: VideoDetail) => (
						<TallCard 
							key={video.id}
							img={video.thumbs[1]} 
							link={`/${category}/${video.id}`} 
							title={video.title.substring(0,25)+"..."} 
							desc={video.desc.substring(0,100)+"..."}
							className="bg-gradient-to-b from-slate-600 to-slate-700  to-black-500"
						/>
					))
				}
			</div>
		</div>
	);
};