import { FC,  useState } from "react";

import { Navbar } from "@/components/Layout/Navbar";
import Sidebar from "@/components/Layout/Sidebar";
import SearchBox from "@/components/Search/SearchBox";

interface Props {
    children?: React.ReactNode
    searchable?: boolean;
	category?:string;
}
const SideBarLayout: FC<Props> = ({ children, searchable = false, category="movie" }) => {
	const [sidebarActive, setSidebarActive] = useState(false);

	return (
		<>	
			<div className="block md:hidden">
				<Navbar/>
			</div>
			<div className="flex">
				
				<div className="override-hidden md:block">
					<Sidebar
						sidebarActive={sidebarActive}
						setSidebarActive={setSidebarActive}
					/>
				</div>
				<div className="flex-grow pb-8 pt-0 overflow-hidden flex flex-col items-stretch">
					{children}
				</div>
				{searchable && (<div className="flex-shrink-0 w-[350px] p-8 sticky top-0 h-screen scrollbar overflow-hidden overflow-y-auto override-hidden xl:block">
					<SearchBox category={category}/>
				</div>)
				}
			</div>
		</>
	);
};

export default SideBarLayout;
