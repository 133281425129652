import { createContext,useContext } from 'react';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';

type Loading = {
    isLoading: boolean;
    setLoading(state:boolean):void;
};

const LoadingContext = createContext<Loading|null>(null);

export const useLoading= () => useContext(LoadingContext) as Loading;

interface Props{
    children: React.ReactNode;
}

export const LoadingProvider:FunctionComponent<Props> = ({ children }) => {
	const ref = useRef<LoadingBarRef>(null);
	const [ isLoading, setLoading ] = useState(false);

	useEffect(()=>{
		isLoading?ref.current?.continuousStart(20, 1000): ref.current?.complete();
		//Set time out to 30s to make sure the loading doesn't run forever
		setTimeout(()=>{
			if(isLoading){
				setLoading(false);
				ref.current?.complete();
			}
		},30000);
	}, [ isLoading ]);

	return (
		<LoadingContext.Provider value={{ isLoading,setLoading }}>
			<LoadingBar color="#00ddff" ref={ref} />
			{isLoading && <div className="fixed inset-0 bg-gray-900 opacity-5 z-[1000]"/>}
			{children}
		</LoadingContext.Provider>
	);
};