import { FC } from "react";

import { VideoDetail } from "@/types";


interface CommentProps {
	data?: VideoDetail;
}

const Comment: FC<CommentProps> = ({ data }) => {
	return (
		<div className="max-w-[92vw] md:max-w-[calc(88vw-300px)]">
			<h1 className="text-2xl mt-10">Comments</h1>
			<div className="my-3">The comment is disabled</div>
		</div>
	);
};

export default Comment;
