import { Hero } from "@components/Home/Hero";
import { TallCard } from "@components/Home/TallCard";
import { VideoList } from "@components/VideoList";
import { FC } from "react";

import SideBarLayout from "@/layouts/SideBarLayout";

interface HomeProps {
	filterConditions?: any
}
export const HomePage: FC<HomeProps> = ({ filterConditions }) => {

	return (
		<SideBarLayout searchable={false}>
			<div className="font-title">
				<Hero />
				<div className="container my-12 mx-auto px-4 md:px-12 h-auto mb-[300px]">
					<p className="mb-2.5 box-border text-center text-xs font-medium uppercase tracking-wider lg:text-left text-[rgba(226,215,3,1)]">Online Streaming</p>
					<div className="mb-7 box-border text-center text-4xl font-bold text-white lg:mb-0 lg:text-left leading-[1.2]">Categories</div>
					<div className="flex flex-wrap -mx-1 lg:-mx-4">
						<TallCard img="/images/home/movies.png" link="/movies" title="Review Film" desc="Watch movies in 10 minutes"/>
						<TallCard img="/images/home/books.jpeg" link="/audiobooks" title="Audio Books" desc="Listen audiobooks" className="bg-gradient-to-b from-cyan-800 to-slate-700  to-cyan-600"/>
						<TallCard img="/images/home/jp.webp" link="/japanese" title="Study Japanese" desc="Study Japanese" className="bg-gradient-to-b from-[#00ff2233] to-slate-700  to-[#aaff00]"/>
						{/* <TallCard img="/images/home/en.jpg" link="/english" title="Study English" desc="Học tiếng Anh" className="bg-gradient-to-b from-[#00ff2233] to-slate-700  to-[#b70000]"/> */}
						<TallCard img="/images/home/skills.webp" link="/investment" title="Investment" desc="Study about investment" className="bg-gradient-to-b from-purple-800 to-slate-700  to-purple-600"/>
					</div>
				</div>
			</div>
		</SideBarLayout>

	);
};
