import { useEffect,useState } from 'react';

const useInfiniteScroll = (callback:()=>void) => {
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	});

	useEffect(() => {
		if (!isFetching) return;
		callback();
	}, [isFetching, callback]);

	const handleScroll=()=>{
		if (document.documentElement.scrollHeight - document.documentElement.scrollTop > document.documentElement.clientHeight || isFetching) return;
		setIsFetching(true);
	}

	//Fix issue: https://pauledenburg.com/typescript-not-all-constituents-of-type-boolean-void-are-callable/
	return [isFetching, setIsFetching] as const;
};

export default useInfiniteScroll;