import { FC, useEffect, useState } from "react";
import {Link} from 'react-router-dom';

import { getSimilarVideos } from "@/api/VideoAPI";
import ImageFade from "@/components/Shared/ImageFade";
import { VideoDetail } from "@/types";

interface SimilarProps {
  data?: VideoDetail;
}

const Similar: FC<SimilarProps> = ({ data }) => {
	const [similarVideos, setSimilarVideos] = useState<VideoDetail[]>([]);
	
	const querySimilarVideos = async()=>{
		if(!data) return;
		const videos = await getSimilarVideos(data);
		setSimilarVideos(videos);
	}
	useEffect(()=>{
		querySimilarVideos();
	}, [data]);
	
	return (
		<div className="flex-shrink-0 md:w-[350px]">
			{similarVideos && similarVideos.length > 0 && (
				<>
					<h1 className="text-2xl my-3">Similar</h1>
					<div className="min-h-[100vh] overflow-x-hidden overflow-y-auto flex flex-col items-stretch gap-2">
						{similarVideos
							.map((video) => (
								<Link
									key={video.id}
									to={`/${video.category}/${video.id}`}
								>
									<div className="flex gap-3 pr-2 hover:brightness-[85%] transition duration-300">
										<div className="flex-shrink-0 h-[100px] w-[70px]">
											<ImageFade
												height={100}
												width={70}
												className="h-full w-full object-cover"
												src={video.thumbs[1]}
												alt=""
											/>
										</div>
										<div className="my-2 flex-grow">
											<p>{video.title.replace("Review Phim", "")}</p>
										</div>
									</div>
								</Link>
							))}
					</div>
				</>
			)}
		</div>
	);
};

export default Similar;
