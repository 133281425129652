import { Cookies } from 'react-cookie';

import * as Encrypt from './encrypt';

const STORAGE_SECRET_KEY = process.env.STORAGE_SECRET_KEY || '12345';

/**
 * Encrypt data and store it into localstorage
 * @param {*} key 
 * @param {*} value 
 */
export const storeLocalData = (key:string, value:any) => {
	let encryptData = '';
	if(value){
		encryptData = Encrypt.encryptData(JSON.stringify(value), STORAGE_SECRET_KEY).toString();
	}
	localStorage.setItem(key, encryptData);
}

/**
 * Get data from local storage and decrypt before return
 * @param {*} key 
 */
export const getLocalData = (key:string) => {
	let data = localStorage.getItem(key);

	if (!data) {
		return null;
	}

	let decryptData = Encrypt.decryptData(data, STORAGE_SECRET_KEY);

	return decryptData;
}

/**
 * Encrypt data and store it into session storage
 * @param {*} key 
 * @param {*} value 
 */
export const storeSessionData = (key:string, value:any) => {
	let encryptData = '';
	if(value){
		encryptData = Encrypt.encryptData(value, STORAGE_SECRET_KEY).toString();
	}
	sessionStorage.setItem(key, encryptData);
}

/**
 * Get data from session storage and decrypt before return
 * @param {*} key 
 */
export const getSessionData = (key:string) => {
	let data = sessionStorage.getItem(key);

	if (!data) {
		return null;
	}

	let decryptData = Encrypt.decryptData(data, STORAGE_SECRET_KEY);

	return decryptData;
}

/**
 * Encrypt data and store it into cookie
 * @param {*} key 
 * @param {*} value 
 */
export const storeCookieData = (key:string, value:any, encrypt:boolean = true, expires:any = null) => {
	const cookies = new Cookies();

	if (!expires) {
		expires = new Date();
		const month = 1
		expires.setMonth(expires.getMonth() + month);
	}

	const option = {
		path: "/",
		expires: expires,
		// maxAge: number,
		// domain: "localhost",
		// secure: true,
	};
	let encryptData = null;
	if(value){
		encryptData = encrypt ? Encrypt.encryptData(value, STORAGE_SECRET_KEY).toString() : value;
	}
	cookies.set(key, encryptData, option);
}

/**
 * Get data from cookie and decrypt before return
 * @param {*} key 
 */
export const getCookieData = (key:string, encrypt:boolean = true) => {
	const cookies = new Cookies();
	let data = cookies.get(key);
	if (!data) {
		return null;
	}
	let decryptData = encrypt ? Encrypt.decryptData(data, STORAGE_SECRET_KEY) : data;
	return decryptData;
}
