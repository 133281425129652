import React, { Dispatch,useContext, useReducer } from 'react';

import { IAction } from '../reducers';

interface PropsType {
    children: React.ReactNode;
    store: (state: any, actions: IAction) => any;
}

const StateContext = React.createContext<any | null>(null);
const DispatchContext = React.createContext<Dispatch<IAction> | null>(null);

export const AppStateProvider = ({ children, store }: PropsType)=>{
	const [ state, dispatch ] = useReducer<(_state: any, _actions: IAction) => any>(store, {});

	return (
		<StateContext.Provider value={state}>
			<DispatchContext.Provider value={dispatch}>
				{children}
			</DispatchContext.Provider>
		</StateContext.Provider>
	);
};

export const useAppState=()=> {
	const ctx = useContext(StateContext);
	if (!ctx) {
		throw new Error('Missing state context');
	}
	return ctx;
};

export function useAppDispatch() {
	const ctx = useContext(DispatchContext);
	if (!ctx) {
		throw new Error('Missing dispatch context');
	}
	return ctx;
}
