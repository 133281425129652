import { FC } from "react";
import {Link} from 'react-router-dom';

import ImageFade from "@/components/Shared/ImageFade";
import { VideoDetail } from "@/types";

interface TopSearchesProps {
  videos?: VideoDetail[];
}

const TopSearches: FC<TopSearchesProps> = ({ videos=[] }) => {
	return (
		<div className="flex flex-col gap-3">
			{videos.map((item) => (
				<Link
					to={`/${item.category}/${item.id}`}
					key={item.id}
				>
					<div className="flex gap-2 hover:brightness-75 transition duration-300">
						<div className="w-[100px] h-[60px] flex-shrink-0">
							<ImageFade
								src={item.thumbs[0]}
								className="w-[100px] h-[60px] object-cover rounded-lg"
								width={100}
								height={60}
								alt=""
							/>
						</div>

						<div>
							<h1>{item.title}</h1>
						</div>
					</div>
				</Link>
			))}
		</div>
	);
};

export default TopSearches;
