import { PrivateRoute } from '@components/Common/PrivateRoute';
import { useFirebase } from '@hooks/useFirebase';
import {ErrorPage} from '@pages/ErrorPage';
import {HistoryPage} from '@pages/HistoryPage';
import {HomePage} from '@pages/HomePage';
import {SignInPage} from '@pages/SignInPage';
import { VideoDetailPage } from '@pages/VideoDetailPage';
import { VideoListPage } from '@pages/VideoListPage';
import { Navigate,Route, Routes } from 'react-router-dom';

export const App = () => {
	const {user} = useFirebase();
	return (
		<Routes>
			<Route path="/" element={<Navigate to="/home" />} />
			<Route path="/home" element={<HomePage />} />
			<Route path="/movies" element={<VideoListPage category={'movie'}/>} />
			<Route path="/audiobooks" element={<PrivateRoute user={user}><VideoListPage category={'audio_book'}/></PrivateRoute>} />
			<Route path="/japanese" element={<PrivateRoute user={user}><VideoListPage category={'japanese'}/></PrivateRoute>} />
			<Route path="/investment" element={<PrivateRoute user={user}><VideoListPage category={'investment'}/></PrivateRoute>} />
			<Route path="/history" element={<PrivateRoute user={user}><HistoryPage /></PrivateRoute>} />
			<Route path="/:category/:id" element={<PrivateRoute user={user}><VideoDetailPage/></PrivateRoute>} />
			<Route path="/sign-in" element={<SignInPage />} />
			<Route path="*" element={<ErrorPage />} />
		</Routes>
	);
};
