// import { init } from '@sentry/react';
import "./styles/globals.css";
import "swiper/css";
import "swiper/css/navigation";
import "react-lazy-load-image-component/src/effects/opacity.css";
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { Root } from './Root';

const rootElementId = 'root';
const rootElement = document.getElementById(rootElementId);

if (!rootElement) { 
	throw new Error(`Cannot find the "#${rootElementId}" element.`);
}

const root = createRoot(rootElement);
root.render(<Root />);
