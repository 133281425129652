import { Link } from "react-router-dom";

interface Props {
	img: string;
	link: string;
	title: string;
	desc: string;
	className?: string;
}
export const TallCard = ({ img, title, link, className, desc }: Props) => {
	className = className || "bg-gradient-to-b from-blue-800 to-slate-700  to-blue-600";
	return (
		<div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 xl:w-1/4 hover:scale-105 transition duration-500">
			<article className={`overflow-hidden rounded-lg shadow-lg ${className}`}>
				<Link to={link}>
					<div className="block h-auto w-full h-[200px] bg-cover" style={{backgroundImage:`url(${img})`}} />
					<p className="absolute mt-[-32px] bg-yellow-300 text-gray-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">FREE</p>
				</Link>
				<header className="flex items-center justify-between leading-tight p-4 pb-0">
					<h1 className="text-lg">
						<Link className="no-underline text-white mb-3 text-xl font-bold text-white-600" to={link}>
							{title}
						</Link>
					</h1>
				</header>
				<p className="text-sm px-4 pt-1 pb-10">
					{desc}
				</p>
			</article>
		</div>
	)
}