import { VideoList } from "@components/VideoList";
import { FC } from "react";

import SideBarLayout from "@/layouts/SideBarLayout";

interface Props {
	category?:string;
	filterConditions?: any
}
export const VideoListPage: FC<Props> = ({ category, filterConditions }) => {

	return (
		<SideBarLayout searchable={true} category={category}>
			<VideoList category={category} filterConditions={filterConditions} />
		</SideBarLayout>

	);
};
