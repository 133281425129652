export const extract=(str:string|undefined, pattern:string|RegExp)=>{
	str = str || '';
	return str.match(pattern) || [];
}

export const randomInRange=(min:number, max:number)=>{  
	return Math.floor(Math.random() * (max - min) + min); 
} 

export const delay=(time:number)=>{
	return new Promise(resolve => setTimeout(resolve, time));
};

export const uppercaseWords = (str:string) => str.toLowerCase().replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

export const uppercaseFirstLetter=(str:string)=> str.charAt(0).toUpperCase() + str.slice(1);

export const openInNewTab = (url:string) => {
	const newWindow = window.open(url, '_blank');
	if (newWindow) newWindow.opener = null
}