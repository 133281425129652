import { Details } from "@components/Common/Details";
import { FC } from "react";

import { VideoDetail } from "@/types";

interface MetaDataProps {
  data?: VideoDetail;
}

const MetaData: FC<MetaDataProps> = ({ data }) => {
	return (
		<div className="flex flex-col gap-[10px]">
			<h1 className="text-3xl mt-5">{data?.title.replace("REVIEW PHIM", "")}</h1>

			<div className="flex gap-4">
				<div className="flex items-center gap-1">
					<img className="w-4 h-4" src="/images/star.png" alt="" />
					<p>{data?.score?.toFixed(1)}</p>
				</div>
				<div className="flex items-center gap-1">
					<img className="w-4 h-4" src="/images/calendar.png" alt="" />
					<p>{data?.date}</p>
				</div>
			</div>
			<Details summary={data?.desc.substring(0, 200)}>
				<p className="whitespace-pre-wrap">{data?.desc}</p>
				<div className="flex gap-3 flex-wrap py-4">
					{data?.tags?.map((tag) => (
						<span key={tag} className="bg-dark-lighten rounded-full px-3 py-1">
							{tag}
						</span>
					))}
				</div>
			</Details>
			
		</div>
	);
};

export default MetaData;
