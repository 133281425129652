import { FC, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import {Link} from 'react-router-dom';

import { getVideoByIds } from "@/api/VideoAPI";
import ImageFade from "@/components/Shared/ImageFade";
import { LOCAL_STORAGE_HISTORY_KEY } from "@/consts";
import SideBarLayout from "@/layouts/SideBarLayout";
import { VideoDetail } from "@/types";
import { getLocalData, storeLocalData } from "@/utils/storage";

export const HistoryPage: FC = () => {
	const [data, setData] = useState<VideoDetail[]>([]);

	const getHistory = () => {
		try {
			const existing = JSON.parse(getLocalData(LOCAL_STORAGE_HISTORY_KEY)||'[]');
			return existing;
		} catch {
			return [];
		}
	};

	const getVideos = async ()=>{
		const ids = getHistory();
		const videos = await getVideoByIds(ids);
		setData(videos);
	}

	useEffect(() => {
		getVideos();
	}, []);

	const clearHistory = () => {
		storeLocalData(LOCAL_STORAGE_HISTORY_KEY,[]);
		setData([]);
	};

	return (
		<SideBarLayout>
			<div className="flex justify-between m-6">
				<h1 className="text-3xl">Watch history</h1>

				<button
					onClick={clearHistory}
					className="text-primary flex items-center gap-1"
				>
					<FaTrash /> <span>Clear</span>
				</button>
			</div>
			{data.length === 0 ? (
				<div className="flex flex-col items-center my-10 gap-6">
					<img className="w-40 h-40 object-cover" src="/images/cinema.svg" alt="" />

					<p className="text-xl">No watch history found</p>

					<Link to="/">
						<div className="text-xl text-primary">Discover more</div>
					</Link>
				</div>
			) : (
				<div className="grid gap-6 grid-cols-sm md:grid-cols-lg p-6">
					{data.map((item: VideoDetail) => (
						<Link
							to={`/video/${item.id}`}
							key={item.id}
						>
							<div className="relative h-0 pb-[163%] bg-dark-lighten rounded overflow-hidden group">
								<div className="absolute top-0 left-0 w-full h-full flex flex-col items-stretch">
									<div className="relative w-full h-0 pb-[140%] flex-shrink-0 group-hover:brightness-[80%] transition duration-300">
										<ImageFade
											width={640}
											className="absolute top-0 left-0 w-full h-full object-cover"
											src={item.thumbs[1]}
											alt=""
										/>
									</div>

									<div className="flex-grow flex items-center">
										<h1 className="w-full whitespace-nowrap overflow-hidden text-ellipsis px-2 group-hover:text-primary transition duration-300">
											{item.title}
										</h1>
									</div>
								</div>
							</div>
						</Link>
					))}
				</div>
			)}
		</SideBarLayout>
	);
};
